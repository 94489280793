import { DialogService }                                              from 'aurelia-dialog';
import { bindable, bindingMode, computedFrom, containerless, inject } from 'aurelia-framework';
import collect                                                        from 'collect.js';
import { ListFilesModal }                                             from 'modules/administration/files/index-modal';
import { DeleteResourceDialog }                                       from 'resources/elements/html-elements/dialogs/delete-resource-dialog';
import { DeleteSelectedResourcesDialog }                              from 'resources/elements/html-elements/dialogs/delete-selected-resources-dialog';
import { InfoDialog }                                                 from 'resources/elements/html-elements/dialogs/info-dialog';

import { AppContainer } from 'resources/services/app-container';
import { SweetAlert }   from 'resources/services/sweet-alert';

@containerless
@inject(AppContainer, DialogService, SweetAlert)
export class Datatable {

    model;
    @bindable listingId;
    @bindable schema = {};

    @bindable({ defaultBindingMode: bindingMode.twoWay }) criteria = {};
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterFormIsDirty;

    @bindable resultsPerPage = 10;
    @bindable limit          = {
        items:    [
            { id: 10, name: '10' },
            { id: 25, name: '25' },
            { id: 50, name: '50' },
            { id: 100, name: '100' },
        ],
        settings: {
            pluginOptions: {
                width: 'auto',
            },
        },
    };
    @bindable recordsSearch;
    @bindable page           = 1;
    @bindable data           = [];
    @bindable pages;

    @bindable selectedRows   = [];
    @bindable allRowsChecked = false;

    @bindable selectedColumns = [];
    @bindable selectedColumnsCollection;

    listingUuid    = generateUuid();
    observers      = [];
    eventListeners = [];
    showColumnList = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param swalService
     */
    constructor(appContainer, dialogService, swalService) {
        this.appContainer    = appContainer;
        this.loggedUser      = appContainer.authenticatedUser.user;
        this.bindingEngine   = appContainer.bindingEngine;
        this.eventAggregator = appContainer.eventAggregator;
        this.storage         = appContainer.localStorage;
        this.notifier        = appContainer.notifier;
        this.i18n            = appContainer.i18n;
        this.dialogService   = dialogService;
        this.swalService     = swalService;

        this.fixLimitItems();
    }

    @computedFrom('page', 'resultsPerPage')
    get pagerStart() {
        if (!this.data || !this.data.length) {
            return 0;
        }

        return this.page * this.resultsPerPage - this.resultsPerPage + 1;
    }

    @computedFrom('page', 'resultsPerPage')
    get pagerEnd() {
        return Math.min(this.page * this.resultsPerPage, this.pagerFiltered);
    }

    @computedFrom('schema')
    get totalColumns() {
        let totalColumns = this.schema.columns.length;

        if (this.schema.edit || this.schema.destroy || this.schema.actions) {
            // incrementing due to actions column
            totalColumns++;
        }

        // incrementing due to selectable column
        return ++totalColumns;
    }

    @computedFrom('schema')
    get visibleButtons() {
        return this.schema.buttons ? this.schema.buttons.filter(button => this.checkVisibility(button)) : [];
    }

    @computedFrom('schema')
    get visibleOptions() {
        return this.schema.options ? this.schema.options.filter(option => this.checkVisibility(option)) : [];
    }

    /**
     * Fixes the limit items' descriptives (that need fixing)
     */
    fixLimitItems() {
        let allItem = this.limit.items.find((item) => item.id === -1);

        if (allItem) {
            allItem.name = this.i18n.tr('text.datatables.show-all');
        }
    }

    /**
     * Stores selected columns
     */
    storeSelectedColumns() {
        this.storeListingSettings('selectedColumns', this.selectedColumns);
    }

    /**
     * Store the number of results per page
     */
    storeResultsPerPage() {
        this.storage.set('datatable-results-per-page-' + this.loggedUser.id, this.resultsPerPage);
    }

    /**
     * Stores selected columns
     */
    retrieveStoredSettingsFromStorage() {
        let settings = JSON.parse(this.storage.get(this.listingId));

        if (settings && settings.selectedColumns) {
            this.selectedColumns.length = 0;

            collect(settings.selectedColumns).each((column) => {
                this.selectedColumns.push(column);
            });

            collect(this.schema.columns).each((column) => {
                column.display = this.selectedColumnsCollection.contains(column.data);
            });
        }

        if (settings && settings.criteria) {
            this.filterFormIsDirty = true;
        }

        this.resultsPerPage = Number(this.storage.get('datatable-results-per-page-' + this.loggedUser.id)) || 10;
    }

    /**
     * Handles bind event
     *
     * @param bindingContext
     */
    bind(bindingContext) {
        this.model = this.model || bindingContext;

        this.prepareSelectedColumns();
        this.retrieveStoredSettingsFromStorage();

        this.initCriteriaColumns();
    }

    /**
     * Inits criteria columns to populate header from them
     *
     * @returns this
     */
    initCriteriaColumns() {
        // init criteria columns to populate header from them
        this.criteria.columns = this.schema.columns.filter((column) => column.display !== false && column.hidden !== true);

        return this;
    }

    /**
     * Handles attached event
     */
    attached() {
        this.subscribeObservers();
        this.subscribeEventListeners();
        this.fixDropdownMenus();

        this.schema.instance = this;

        if (this.schema.searchImmediately !== false) {
            this.load();
        }
    }

    /**
     * Handles detached event
     */
    detached() {
        this.ready = false;

        this.disposeEventListeners();
    }

    /**
     * Prepares selected columns
     *
     * @returns this
     */
    prepareSelectedColumns() {
        this.selectedColumns.splice(
            0,
            this.selectedColumns.length,
            ...collect(this.schema.columns)
                .filter((column) => column.hidden !== true && column.display !== false)
                .pluck('data')
                .toArray(),
        );

        this.selectedColumnsCollection = collect(this.selectedColumns);

        return this;
    }

    /**
     * Subscribe observers
     */
    subscribeObservers() {
        this.observers.push(
            this.bindingEngine
                .collectionObserver(this.selectedColumns)
                .subscribe((splices) => {
                    collect(this.schema.columns).each((column) => {
                        column.display = this.selectedColumnsCollection.contains(column.data);
                        this.storeSelectedColumns();
                        this.criteria.columns = this.schema.columns.filter((column) => column.display !== false && column.hidden !== true);
                    });
                }),
            this.bindingEngine
                .propertyObserver(this, 'resultsPerPage')
                .subscribe((nv, ov) => this.storeResultsPerPage(nv, ov)),
        );
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `datatable-must-be-reloaded` event
        this.eventListeners.push(this.eventAggregator.subscribe('datatable-must-be-reloaded', (info) => {
            if (!info.listingId || info.listingId === this.listingId) {
                // stores listing criteria in storage
                this.storeFilterCriteria(info.criteria);
                this.submitFilter(info.criteria);
                this.reload();
            }
        }));

        // subscribes `datatable-filter-must-be-reseted` event
        this.eventListeners.push(
            this.eventAggregator.subscribe('datatable-filter-must-be-reseted', (arg1) => {
                let listingId;
                let ignoredFilters = [];

                if (typeof arg1 === 'object' && arg1 !== null) {
                    ({ listingId, ignoredFilters } = arg1);
                } else {
                    listingId = arg1;
                }

                if (this.listingId === listingId) {
                    this.clearFilterCriteria(ignoredFilters);
                    this.resetFilter(ignoredFilters);
                }
            }),
        );

        // subscribes `datatable-must-be-reloaded` event
        this.eventListeners.push(this.eventAggregator.subscribe('locale-changed', () => {
            this.submitFilter();
            this.reload();
        }));
    }

    /**
     * Checks all rows
     *
     */
    checkAllRows() {
        this.uncheckAllRows();

        setTimeout(() => {
            if (this.allRowsChecked) {
                this.data.forEach((row) => {
                    if (this.checkSelectability(row)) {
                        this.selectedRows.push(row.id);
                    }
                });
            }
        }, 0);
    }

    /**
     * Sets all rows checked
     *
     * @param newValue
     */
    setChecked(newValue) {
        this.allRowsChecked = newValue;
        this.checkAllRows();
    }

    /**
     * Unchecks all rows
     *
     */
    uncheckAllRows() {
        this.selectedRows.splice(0, this.selectedRows.length);
    }

    /**
     * Disposes event listeners
     */
    disposeEventListeners() {
        this.eventListeners.forEach((eventListener) => eventListener.dispose());

        this.eventListeners = [];
    }

    /**
     * Handles page changing
     */
    pageChanged() {
        if (!this.ready) {
            return;
        }

        this.reload();
    }

    /**
     * Handles results per page changing
     */
    resultsPerPageChanged() {
        if (!this.ready) {
            return;
        }

        this.reload();
    }

    /**
     * Handles records search changing
     *
     * @param newValue
     * @param oldValue
     */
    recordsSearchChanged(newValue, oldValue) {
        if (!this.ready) {
            return;
        }

        this.reload();
    }

    /**
     * Loads data from remote source
     *
     * TODO - this method needs to be refactored!
     */
    load() {
        this.prepareColumnsCriteria();
        this.assignFilterModelToCriteria();
        this.assignStickyFiltersToCriteria();

        this.criteria.search   = { value: this.recordsSearch, regex: false };
        this.criteria.start    = (this.page - 1) * this.resultsPerPage;
        this.criteria.length   = this.resultsPerPage;
        this.criteria.columns  = this.schema.columns;
        this.criteria.order    = [];
        this.criteria.order[0] = {
            column: this.schema.sorting.column,
            dir:    this.schema.sorting.direction,
        };

        this.schema
            .repository
            .search(this.criteria)
            .then(response => {
                this.data          = response.data;
                this.pagerTotal    = response.recordsTotal;
                this.pagerFiltered = response.recordsFiltered;
                this.pages         = Math.ceil(response.recordsFiltered / this.resultsPerPage);
                this.page          = this.page <= this.pages ? this.page : 1;
                this.ready         = true;

                // fix the dropdown menus existing issue with scrolling tables
                setTimeout(() => {
                    // popover init
                    $('#' + this.listingId).find('[data-toggle="popover"]').popover({ html: true });

                    this.fixDropdownMenus()
                }, 0);
            })
            .catch((error) => this.notifier.dangerNotice(this.i18n.tr('text.error-message.action-failed')));
    }

    /**
     * Reloads data from remote source
     */
    reload() {
        this.load(); // this.pageChanged() won't trigger if the current page is already page 1.
        this.uncheckAllRows();
        this.allRowsChecked = false;
    }

    doRedirectTo(route) {
        return this.appContainer.router.navigateToRoute(route)
    }

    /**
     * Handles show action button
     *
     * @param row
     *
     * @returns {boolean|*}
     */
    doShow(row) {
        let showRoute = null;

        if (!this.checkVisibility(this.schema.show, row)) {
            return false;
        }

        // TODO: Review this when refactoring datatables!!!
        if (this.schema.show && this.schema.show.action instanceof Function) {
            return this.doCustomAction(this.schema.show, row);
        }

        if (this.schema.show === true || this.schema.show.action === true) {
            showRoute = this.getDefaultShowRoute();
        } else {
            if (this.schema.show instanceof Function) {
                return this.doCustomAction({ action: this.schema.show }, row);
            }

            showRoute = this.schema.show;
        }

        return this.model.appContainer.router.navigateToRoute(showRoute, { id: row.id });
    }

    /**
     * Evaluates if row can be displayed
     *
     * @param row
     *
     * @returns {boolean|*}
     */
    displayRow(row) {
        if (this.schema.rowFilter && this.schema.rowFilter instanceof Function) {
            return this.schema.rowFilter(row);
        }

        return true;
    }

    /**
     * Handles edit action button
     *
     * @param row
     *
     * @returns {boolean|*}
     */
    doEdit(row) {
        let editRoute = null;

        if (!this.checkVisibility(this.schema.edit, row)) {
            return false;
        }

        // TODO: Review this when refactoring datatables!!!
        if (this.schema.edit && this.schema.edit.action instanceof Function) {
            return this.doCustomAction(this.schema.edit, row);
        }

        if (this.schema.edit === true || this.schema.edit.action === true) {
            editRoute = this.getDefaultEditRoute();
        } else {
            if (this.schema.edit instanceof Function) {
                return this.doCustomAction({ action: this.schema.edit }, row);
            }

            editRoute = this.schema.edit;
        }

        return this.model.appContainer.router.navigateToRoute(editRoute, { id: row.id });
    }

    /**
     * Handles destroy action button
     *
     * @param row
     *
     * @returns {*}
     */
    doDestroy(row) {
        if (!this.schema.destroy || !this.checkVisibility(this.schema.destroy, row)) {
            return false;
        }

        if (this.schema.destroy instanceof Function) {
            return this.doCustomAction({ action: this.schema.destroy }, row);
        }

        this.dialogService.open({
            viewModel: DeleteResourceDialog,
            model:     {
                resource: this.schema.resource,
                action:   {
                    method:     this.schema.destroy.action instanceof Function ? this.schema.destroy.action.bind(this.schema.destroy.action) : this.schema.repository.destroy.bind(this.schema.repository),
                    parameters: this.schema.destroy.action instanceof Function ? [row] : [row.id],
                },
            },
        }).whenClosed((response) => {
            if (!response.wasCancelled) {
                this.reload();
                this.destroyed(row);
            }
        });
    }

    /**
     * Handles destroy action button
     *
     * @returns {*}
     */
    doDestroySelected() {
        if (!this.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.i18n.tr('message.select-at-least-one-record'),
                    title: this.i18n.tr('text.attention'),
                },
            });
        }

        if (this.schema.destroySelected instanceof Function) {
            return this.doCustomAction({ action: this.schema.destroySelected });
        }

        this.dialogService.open({
            viewModel: DeleteSelectedResourcesDialog,
            model:     {
                resource: this.schema.resource,
                action:   {
                    method:     this.schema.repository.destroySelected.bind(this.schema.repository),
                    parameters: [this.selectedRows],
                },
            },
        }).whenClosed((response) => {
            if (!response.wasCancelled) {
                this.destroyedSelected();
                this.reload();

                this.uncheckAllRows();
            }
        });
    }

    /**
     * Handles destroy action button
     *
     * @returns {*}
     */
    destroyLocally($index) {
        return new Promise((resolve, reject) => {
            this.data.splice($index, 1);

            resolve(true);
            reject(new Error('Error'));
        });
    }

    /**
     * Handles `destroyed` event
     *
     * @param row
     *
     * @returns {*}
     */
    destroyed(row) {
        if (this.schema.destroyed instanceof Function) {
            return this.schema.destroyed(row);
        }
    }

    /**
     * Handles `destroyed selected` event
     *
     *
     * @returns {*}
     */
    destroyedSelected() {
        this.allRowsChecked = false;

        if (this.schema.destroyedSelected instanceof Function) {
            return this.schema.destroyedSelected();
        }

        if (this.schema.destroyed instanceof Function) {
            return this.schema.destroyed();
        }
    }

    /**
     * Handles custom action button
     *
     * @param row
     *
     * @returns {*}
     */
    doShowFiles(row) {
        this.schema.fileSettings.relatableModel = row;

        this.dialogService.open({ viewModel: ListFilesModal, model: this.schema.fileSettings });
    }

    /**
     * Handles custom action button
     *
     * @param action
     *
     * @returns {*}
     */
    doButtonAction(action) {
        if (action instanceof Function) {
            return action(() => this.reload());
        }
    }

    /**
     * Handles custom cell button
     *
     * @param action
     * @param row
     * @param cell
     * @param element
     *
     * @returns {*}
     */
    doCellAction(action, row, cell, element) {
        if (action instanceof Function) {
            return action(row, cell, element);
        }
    }

    /**
     * Handles custom action button
     *
     * @param action
     * @param row
     * @param $index
     *
     * @returns {*}
     */
    doCustomAction(action, row, $index) {
        if (action.action instanceof Function && !this.checkDisabled(action, row)) {
            return action.action(row, $index);
        }
    }

    /**
     * Checks if action button shall be disabled
     *
     * @param action
     * @param row
     *
     * @returns {boolean}
     */
    checkDisabled(action, row) {
        let disabled = false;

        if (action && action.disabled instanceof Function) {
            disabled = action.disabled(row);
        }

        if (action && typeof (action.disabled) === 'boolean') {
            disabled = action.disabled;
        }

        return disabled;
    }

    /**
     * Checks if action button shall be visible
     *
     * @param action
     * @param row
     *
     * @returns {boolean}
     */
    checkVisibility(action, row) {
        let visible = false;

        // TODO: This is here only for backward compatibility!!!
        if (typeof (action) !== 'undefined' && action !== null) {
            visible = true;
        }

        if (action && action.visible instanceof Function) {
            visible = action.visible(row);
        }

        if (action && typeof (action.visible) === 'boolean') {
            visible = action.visible;
        }

        return visible;
    }

    /**
     * Check whether the row is selectable or not
     *
     * @param column
     * @param row
     *
     * @return {boolean}
     */
    checkSelectability(row) {
        if (this.schema.selectable instanceof Function) {
            return this.schema.selectable(row);
        }

        return this.schema.selectable;
    }

    /**
     * Checks whether the actions dropdown menu divider shall be visible
     *
     * @param row
     *
     * @returns {boolean}
     */
    checkActionsDividerVisibility(row) {
        let visible = false;

        this.schema.actions.forEach(action => visible = visible || this.checkVisibility(action, row));

        return visible;
    }

    /**
     * Does data sorting
     *
     * @param column
     */
    doSort(column) {
        if (this.schema.columns[column].sortable === false || this.schema.columns[column].orderable === false) {
            return;
        }

        let oldColumn    = this.schema.sorting.column;
        let oldDirection = this.schema.sorting.direction;

        this.schema.sorting.column    = column;
        this.schema.sorting.direction = 'asc';

        if (column === oldColumn) {
            this.schema.sorting.direction = oldDirection === 'asc' ? 'desc' : 'asc';
        }

        this.reload();
    }

    /**
     * Triggers an event
     *
     * @param event
     * @param payload
     *
     * @returns {boolean|*}
     */
    triggerEvent(event, payload = {}) {
        payload.bubbles = true;

        return this.element.dispatchEvent(new CustomEvent(event, payload));
    }

    selected(row) {
        if (this.select) {
            return this.select(row);
        }
    }

    /**
     * Prepares columns criteria
     */
    prepareColumnsCriteria() {
        // TODO - THINK OF A BETTER WAY TO HANDLE THIS
        let length = this.schema.columns.length;

        for (let i = 0; i < length; i++) {
            this.schema.columns[i].searchable = this.schema.columns[i].searchable !== undefined ? this.schema.columns[i].searchable : true;
            this.schema.columns[i].orderable  = this.schema.columns[i].orderable !== undefined ? this.schema.columns[i].orderable : true;
            this.schema.columns[i].search     = { value: '', regex: false };
        }
    }

    /**
     * Stores filter criteria
     *
     * @param criteria
     */
    storeFilterCriteria(criteria) {
        if (typeof criteria !== 'undefined' && criteria !== null) {
            this.storeListingSettings('criteria', criteria);
        }
    }

    /**
     * Clears the stored filter criteria, except the ones specified in ignoredFilters
     */
    clearFilterCriteria(ignoredFilters = []) {
        let settings = JSON.parse(this.storage.get(this.listingId)) || {};

        if (settings.criteria) {
            Object.keys(settings.criteria).forEach(key => {
                if (!ignoredFilters.includes(key)) {
                    delete settings.criteria[key];
                }
            });

            if (Object.keys(settings.criteria).length === 0) {
                delete settings.criteria;
            }
        }

        if (Object.keys(settings).length === 0) {
            this.storage.remove(this.listingId);
        } else {
            this.storage.set(this.listingId, JSON.stringify(settings));
        }
    }

    /**
     * Adds the given key & value to the local storage settings
     *
     * @param key
     * @param value
     */
    storeListingSettings(key, value) {
        let settings = JSON.parse(this.storage.get(this.listingId)) || {};

        settings[key] = value;

        this.storage.set(this.listingId, JSON.stringify(settings));
    }

    /**
     * Submits filter
     *
     * @param criteria
     */
    submitFilter(criteria) {
        if (typeof criteria !== 'undefined' && criteria !== null) {
            this.assignFilterModelToCriteria();

            this.filterFormIsDirty = true;
        }
    }

    /**
     * Assigns filter model to criteria
     */
    assignFilterModelToCriteria() {
        if (typeof this.model.filterModel !== 'undefined' && this.model.filterModel !== null) {
            Object.keys(this.model.filterModel).forEach((key, index) => {
                this.criteria[key] = this.model.filterModel[key];
            });
        }
    }

    /**
     * Assigns filter model to criteria
     */
    assignStickyFiltersToCriteria() {
        if (typeof this.schema.stickyFilters !== 'undefined' && this.schema.stickyFilters !== null) {
            Object.keys(this.schema.stickyFilters).forEach((key, index) => {
                this.criteria[key] = this.schema.stickyFilters[key];
            });
        }
    }

    /**
     * Resets filter, except the ones specified in ignoredFilters
     */
    resetFilter(ignoredFilters = []) {
        Object.keys(this.model.filterModel).forEach((key) => {
            if (!ignoredFilters.includes(key)) {
                if (this.model.filterModel[key] instanceof Array) {
                    this.model.filterModel[key].splice(0, this.model.filterModel[key].length);
                } else {
                    this.model.filterModel[key] = null;
                }
            }
        });

        // Reset criteria except for ignored filters
        this.criteria = Object.keys(this.criteria).reduce((newCriteria, key) => {
            if (ignoredFilters.includes(key)) {
                newCriteria[key] = this.criteria[key];
            }
            return newCriteria;
        }, {});

        this.reload();

        this.filterFormIsDirty = false;
    }

    /**
     * Gets default edit route
     * Assumes that it is equal to the current route replacing index by edit.
     *
     * @returns string
     */
    getDefaultEditRoute() {
        let currentRoute = this.model.appContainer.router.currentInstruction.config.name;

        return currentRoute.replace('index', 'edit');
    }

    /**
     * Gets default edit route
     * Assumes that it is equal to the currente route replacing index by edit.
     *
     * @returns string
     */
    getDefaultShowRoute() {
        let currentRoute = this.model.appContainer.router.currentInstruction.config.name;

        return currentRoute.replace('index', 'view');
    }

    /**
     * Returns the current sorting column and direction
     *
     * @returns {*}
     */
    getSortingColumn() {
        return {
            column: this.schema.columns[this.schema.sorting.column].name,
            dir:    this.schema.sorting.direction,
        };
    }

    /**
     * Returns the given row's unique identifier
     *
     * @param row
     */
    getRowUuid(row) {
        return row.__generated_uuid || this.generateRowUuid(row);
    }

    /**
     * Generates a new unique identifier for the given row, if it doesn't already have one
     *
     * @param row
     */
    generateRowUuid(row) {
        if (!row.__generated_uuid) {
            row.__generated_uuid = generateUuid();
        }

        return row.__generated_uuid;
    }

    /**
     * Determines if a column is specified in the totalRow.columns array.
     *
     * @param {Object} column
     * @returns {Boolean}
     */
    isTotalColumn(column) {
        return this.schema.totalRow.columns.some(totalColumn => totalColumn.data === column.data);
    }

    /**
     * Filters and returns the displayed columns.
     *
     * @returns {Array}
     */
    @computedFrom('schema.columns')
    get displayedColumns() {
        let columns = this.schema.columns.slice();

        if (this.schema.selectable) {
            columns.unshift({ data: 'selectable' });
        }
        return columns.filter(column => column.display !== false && column.hidden !== true);
    }

    /**
     * Computes the index of the first total column among displayed columns.
     *
     * @returns {Number}
     */
    @computedFrom('displayedColumns', 'schema.totalRow.columns')
    get firstTotalColumnIndex() {
        let columns = this.schema.columns.slice();

        if (this.schema.selectable) {
            columns.unshift({ data: 'selectable' });
        }

        return columns.findIndex(column => this.isTotalColumn(column));
    }

    /**
     * Computes the index where the "Total" label should be placed.
     *
     * @returns {Number}
     */
    @computedFrom('displayedColumns', 'firstTotalColumnIndex')
    get totalLabelColumnIndex() {
        if (this.firstTotalColumnIndex === -1) {
            return -1;
        }

        for (let i = this.firstTotalColumnIndex - 1; i >= 0; i--) {
            if (!this.isTotalColumn(this.displayedColumns[i])) {
                return i;
            }
        }

        for (let i = this.firstTotalColumnIndex + 1; i < this.displayedColumns.length; i++) {
            if (!this.isTotalColumn(this.displayedColumns[i])) {
                return i;
            }
        }

        return 0;
    }

    /**
     * Computes the CSS classes for the total cells based on thresholds.
     *
     * @returns {Object}
     */
    @computedFrom('totals')
    get totalRowClasses() {
        const totalRowClasses = {};
        const totals          = this.totals;

        this.schema.totalRow.columns.forEach(column => {
            const columnData = column.data;
            const totalValue = totals[columnData];

            if (totalValue == null || !column.thresholds) {
                totalRowClasses[columnData] = '';
                return;
            }

            const thresholds = column.thresholds.slice().sort((a, b) => b.value - a.value);

            for (const threshold of thresholds) {
                if (totalValue > threshold.value) {
                    totalRowClasses[columnData] = threshold.class;
                    return;
                }
            }

            totalRowClasses[columnData] = '';
        });

        return totalRowClasses;
    }

    /**
     * Computes the totals for the specified columns.
     *
     * @returns {Object}
     */
    @computedFrom('data')
    get totals() {
        const totals = {};
        if (!this.data || !this.data.length) {
            return totals;
        }

        this.schema.totalRow.columns.forEach(totalColumn => {
            const columnData   = totalColumn.data;
            totals[columnData] = this.data.reduce((sum, row) => {
                let rawValue = row[columnData];

                // Handle nested properties if necessary
                if (typeof rawValue === 'object' && rawValue !== null && 'value' in rawValue) {
                    rawValue = rawValue.value;
                }

                let value = parseFloat(rawValue);

                if (isNaN(value) && typeof rawValue === 'string') {
                    value = parseFloat(rawValue.replace(',', '.'));
                }

                // handle NaN values
                if (isNaN(value)) {
                    return sum;
                }

                return sum + value;
            }, 0);
        });

        return totals;
    }

    /**
     * Fixes the datatable's action dropdown menus existing issue with scrolling containers
     */
    fixDropdownMenus() {
        $(document).on('click', `.${this.listingId}-options-dropdown`, function (e) {
            e.stopPropagation();
        });

        // detaches the dropdown element from the table column, and appends it to the `body`
        $(`.${this.listingId}-actions-dropdown`).on('show.bs.dropdown', function () {
            $('body')
                .append($(this).css({
                            position: 'absolute',
                            left:     $(this).offset().left,
                            top:      $(this).offset().top,
                        })
                        .detach(),
                );
        });

        // re-attaches the dropdown element to its original location
        $(`.${this.listingId}-actions-dropdown`).on('hidden.bs.dropdown', function () {
            let originalElement = $(this).data('origin');

            $(`#${originalElement}`)
                .append($(this).css({
                            position: '',
                            left:     '',
                            top:      '',
                        })
                        .detach(),
                );
        });
    }

    /**
     * Toggles the column list
     *
     * @param event
     */
    toggleColumnList(event) {
        event.preventDefault();
        event.stopPropagation();
        this.showColumnList = !this.showColumnList;
    }

}

export class checkNullValueConverter {

    toView(value) {
        return (value === null || value === undefined) ? '' : value;
    }
}

export class NumberFormatValueConverter {
    toView(value) {
        return value != null ? value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '';
    }
}
