import { DialogService }              from 'aurelia-dialog';
import { bindable, inject }           from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { SanitizeHtmlValueConverter } from 'resources/value-converters/text-converters';
import 'jquery.fancytree/dist/modules/jquery.fancytree.edit';
import 'jquery.fancytree/dist/modules/jquery.fancytree.filter';
import 'jquery.fancytree/dist/modules/jquery.fancytree.persist';

@inject(AppContainer, SanitizeHtmlValueConverter, DialogService)
export class FancyTree {

    defaults = {
        id:             'fancy-tree-' + (Math.floor(Math.random() * 65000)),
        controlButtons: true,
        fullPath:       true,
        shouldPersist:  false, // override this if you wish to persist the tree's state in localStorage
        checkbox:       false,
        filterable:     false,
        filterSchema:   null,
        extensions:     [],
        renderNode:     (event, data) => {
            let node = data.node;

            $(data.node.span).attr('title', node.title);
        },
    };

    @bindable options   = {};
    @bindable maxHeight = null;
    @bindable filterText;

    eventListeners  = [];
    customButtons   = [];
    treeContainer   = null;
    tree            = null;
    fullPathOnClick = false;
    fullName        = null;
    path            = null;

    /**
     * Constructor
     *
     * @param appContainer
     * @param sanitizeHtml
     * @param dialogService
     */
    constructor(appContainer, sanitizeHtml, dialogService) {
        this.appContainer  = appContainer;
        this.sanitizeHtml  = sanitizeHtml;
        this.dialogService = dialogService;
    }

    /**
     * Handles attached event
     */
    attached() {
        this.customButtons   = this.options.customButtons || [];
        this.fullPathOnClick = this.options.fullPathOnClick || false;

        this.subscribeEventListeners();

        this.createElement();
    }

    /**
     * Handles detached event
     */
    detached() {
        this.disposeEventListeners();
    }

    /**
     * Returns tree
     *
     * @returns {*|jQuery}
     */
    getTree() {
        return $(this.treeContainer).fancytree('getTree');
    }

    /**
     * Selects nodes
     *
     * @param nodes
     */
    selectNodes(nodes) {
        nodes = Array.isArray(nodes) ? nodes : [nodes];

        this.getTree().visit(node => {
            // Use node.key as a string directly, without parsing to an integer
            let nodeKey  = node.key;
            let selected = nodes.indexOf(nodeKey) >= 0;

            if (this.settings.nodeBaseInteractions.indexOf('select') !== -1) {
                node.setSelected(selected);
            }

            if (this.settings.nodeBaseInteractions.indexOf('focus') !== -1) {
                node.setFocus(selected);
            }

            if (this.settings.nodeBaseInteractions.indexOf('active') !== -1) {
                node.setActive(selected);
            }

            // if the node is selected and the selection mode is single
            // then expand all parents from root up to selected node
            // (even if backend sent them specifically collapsed)
            if (selected && !Array.isArray(nodes)) {
                node.getParentList().forEach(parentNode => parentNode.setExpanded(true));
            }
        });
    }

    /**
     * Function that clears all nodes of having the checkbox = true parameter if settings demand it
     * (clears children recursivelly as well)
     *
     * @param settings
     * @param nodes
     */
    clearNodesListOfCheckbox(settings, nodes) {
        nodes.forEach(node => {
            node.checkbox = settings.checkbox ? node.checkbox : false;

            if (node.children) {
                this.clearNodesListOfCheckbox(settings, node.children);
            }
        });
    }

    /**
     * Creates element
     */
    createElement() {
        this.settings = $.extend({}, this.defaults, this.options);

        this.handlePersistSetting();
        this.handleFilterableSetting();
        this.handleActivateCallbackSetting();

        this.fetchData().then(response => {
            response = this.sanitizeTitles(response);

            this.settings.source = response.map(nodeInfo => {
                if (nodeInfo.children) {
                    return {
                        ...nodeInfo,
                        children: nodeInfo.children.map(child => {
                            let childrenTitle = child.title;
                            return {
                                ...child,
                                title: (childrenTitle.trim() === '') ? '&nbsp;' : childrenTitle,
                            };
                        }),
                        title:    nodeInfo.title,
                    };
                }
                return {
                    ...nodeInfo,
                    title: nodeInfo.title,
                };
            });

            // fancy tree sometimes does not feel like obeying the setting.checkbox if response has nodes with checkbox = true;
            // this forces all nodes to be false if this.settings.checkbox is falsy
            this.clearNodesListOfCheckbox(this.settings, this.settings.source);

            this.settings.click = this.onClick.bind(this);

            $(this.treeContainer).fancytree(this.settings);

            if (this.settings.initial_nodes) {
                this.selectNodes(this.settings.initial_nodes);
            }

            if (this.settings.activatedKey) {
                this.getTree().activateKey(this.settings.activatedKey);
            }

            this.handleInitialNodesSetting();
            this.handleMutateNodesSetting();

            if (this.maxHeight) {
                $(this.treeContainer).find('.ui-fancytree').css('max-height', this.maxHeight);
            }

            this.generateFullPath({}, { node: $(this.treeContainer).fancytree('getActiveNode') });
        });

        this.options.instance = this;
    }

    /**
     * Handles `filterable` setting
     */
    handleFilterableSetting() {
        if (this.settings.filterable === true) {
            if (!this.settings.extensions.find(extension => extension === 'filter')) {
                this.settings.extensions.push('filter');
            }

            this.settings.quicksearch = true;

            this.settings.filter = {
                autoApply:           true,   // Re-apply last filter if lazy data is loaded
                autoExpand:          true,   // Expand all branches that contain matches while filtered
                counter:             false,  // Show a badge with number of matching child nodes near parent icons
                fuzzy:               false,  // Match single characters in order, e.g. 'fb' will match 'FooBar'
                hideExpandedCounter: true,   // Hide counter badge if parent is expanded
                hideExpanders:       true,   // Hide expanders if all child nodes are hidden by filter
                highlight:           true,   // Highlight matches by wrapping inside <mark> tags
                leavesOnly:          false,  // Match end nodes only
                nodata:              this.appContainer.i18n.tr('text.no-records-to-display'), // Display a 'no data' status node if result is empty
                mode:                'hide', // Grayout unmatched nodes (pass "hide" to remove unmatched node instead)
            };
        }
    }

    /**
     * Handles `activateCallback` setting
     */
    handleActivateCallbackSetting() {
        const activateCallback = this.settings.activate;

        if (activateCallback instanceof Function) {
            this.settings.activate = (event, data) => {
                const result = activateCallback(event, data);

                if (this.settings.fullPath && !this.fullPathOnClick) {
                    this.generateFullPath(event, data);
                }

                return result;
            };
        }
    }

    /**
     * Fetches tree
     */
    fetchData() {
        if (this.options.repository && this.options.repository.source && this.options.repository.method) {
            let repository = this.options.repository.source;
            let method     = this.options.repository.method;

            // if arrayParam then feed each array value as separate param
            if (this.options.repository.paramArray) {
                return repository[method](...this.options.repository.params);
            }

            // else just pass whatever it is as a single param
            return repository[method](this.options.repository.params);
        }

        return Promise.resolve(this.options.source);
    }

    /**
     * Reloads tree
     */
    reload() {
        this.fetchData().then((response) => {
            $(this.treeContainer).fancytree('option', 'source', this.sanitizeTitles(response));
            this.handleInitialNodesSetting();
        });
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `reload-fancy-tree` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('reload-fancy-tree', tree => {
                if (tree.id === this.options.id) {
                    this.reload();
                }
            }),
        );
    }

    /**
     * Disposes event listeners
     */
    disposeEventListeners() {
        this.eventListeners.forEach(eventListener => eventListener.dispose());
        this.eventListeners.splice(0, this.eventListeners.length);
    }

    /**
     * Collapses every node immediately without animation
     */
    collapseAll(noAnimation = false) {
        $(this.treeContainer).fancytree('getRootNode').visit(node => {
            node.setExpanded(false, { noAnimation });
        });
    }

    /**
     * Expands every node
     */
    expandAll() {
        $(this.treeContainer).fancytree('getRootNode').visit(node => node.setExpanded(true));
    }

    /**
     * Setups the tree's settings for persistence
     */
    handlePersistSetting() {
        if (this.settings.shouldPersist === true) {
            this.settings.extensions.push('persist');

            this.settings.persist = {
                cookieDelimiter: '~',
                cookiePrefix:    'tree-persistence-' + this.settings.id + '-',
                cookie:          {
                    raw:     false,
                    expires: '',
                    path:    '',
                    domain:  '',
                    secure:  false,
                },
                expandLazy:      true,
                expandOpts:      {
                    noAnimation: true,
                    noEvents:    true,
                },
                overrideSource:  true,
                store:           'local',
                types:           'active expanded focus selected',
            };
        }
    }

    /**
     * Handles `initialNodes` setting
     */
    handleInitialNodesSetting() {
        if (this.settings.initial_nodes) {
            this.selectNodes(this.settings.initial_nodes);
        }
    }

    /**
     * Handles `mutateNodes` setting
     */
    handleMutateNodesSetting() {
        if (this.settings.mutateNodes instanceof Function) {
            this.mutateNodes();
        }
    }

    /**
     * Mutates nodes
     */
    mutateNodes() {
        this.getTree().visit(node => this.settings.mutateNodes(node));
    }

    /**
     * Generates full path
     *
     * @param event
     * @param data
     */
    generateFullPath(event, data) {
        if (typeof this.options.click === 'function' && data.node) {
            let selectedNode    = data.node;
            let parentNodeNames = [];

            // Traverse up the tree until we reach the root node
            while (selectedNode.parent) {
                parentNodeNames.push(selectedNode.parent.title);
                selectedNode = selectedNode.parent;
            }

            parentNodeNames.pop();
            let fullName = parentNodeNames.reverse().join(' » ');

            if (parentNodeNames.length > 0) {
                fullName += ' » ';
            }

            fullName += data.node.title;
            this.fullName = fullName;
        }
    }

    /**
     * Resets fancy tree filter
     */
    resetFilter() {
        let activeNode = $(this.treeContainer).fancytree('getActiveNode');
        if (activeNode && typeof this.options.click === 'function') {
            this.options.click({}, { node: activeNode, tree: activeNode.tree });
            this.fullName = null;
        }
    }

    /**
     * Handles filter value
     */
    filterTextChanged() {
        this.filterText && this.filterText.length
            ? this.filterNodes()
            : this.clearFilter();
    }

    /**
     * Filters tree nodes
     */
    filterNodes() {
        this.collapseAll(true);
        this.getTree().filterNodes(this.filterText);
    }

    /**
     * Clears filter
     */
    clearFilter() {
        this.filterText = null;

        this.getTree().clearFilter();
    }

    /**
     * Handles click event
     *
     * @param event
     * @param data
     */
    onClick(event, data) {
        if (this.settings.fullPath && this.fullPathOnClick) {
            this.generateFullPath(event, data);
        }
    }

    sanitizeTitles(nodes) {
        for (let prop in nodes) {
            if (prop === 'title') {
                nodes[prop] = this.sanitizeHtml.sanitizeString(nodes[prop], { ALLOWED_TAGS: [] });
            }
            if (nodes[prop] instanceof Object)
                this.sanitizeTitles(nodes[prop]);
        }
        return nodes;
    }

    /**
     * Opens the filter modal
     */
    openFilterModal() {
        if (!this.settings.filterSchema || !this.settings.filterSchema.viewModel) {
            console.error('Filter schema is not configured properly');
            return;
        }

        this.dialogService.open({
            viewModel: this.settings.filterSchema.viewModel,
            model:     this.settings.filterSchema.model,
        }).whenClosed(result => {
            if (!result.wasCancelled) {
                console.log('Filter result:', result);
                this.applyFilter(result.output);
            }
        });
    }

    /**
     * Applies filter
     *
     * @param filterCriteria
     */
    applyFilter(filterCriteria) {
        this.options.repository.params = filterCriteria;
        this.reload();
    }
}
