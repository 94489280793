import { DialogController } from 'aurelia-dialog';
import { inject, useView }  from 'aurelia-framework';
import { CustomDialog }     from 'resources/elements/html-elements/dialogs/custom-dialog';
import { AppContainer }     from 'resources/services/app-container';

@useView('resources/elements/html-elements/dialogs/custom-dialog.html')
@inject(AppContainer, DialogController)
export class ConfirmReopenLotDialog extends CustomDialog {

    defaultSettings = {
        title:         'dialog.title.confirm-reopen-lot',
        body:          'dialog.body.confirm-reopen-lot',
        resource:      null,
        buttons:       false,
        customButtons: [
            {
                class:  'btn-link',
                action: () => this.cancel(),
                label:  'dialog.button.cancel',
            },
            {
                class:  'btn-warning',
                action: () => this.submit(),
                label:  'dialog.button.reopen-lot',
            },
        ],
    };

}
